import Navbar from './components/Navbar';
import Discover from './components/Discover';
import About from './components/About';
import Process from './components/Process';
// import Team from './components/Team';
import ContactUs from './components/ContactUs';
import Footer from './components/Footer';
import {BrowserRouter as Router} from 'react-router-dom';

function App() {
  return (
    <Router>
      <div className="App">
        <Navbar />
        <Discover />
        <About />
        <Process />
        <ContactUs />
        <Footer />
      </div>
    </Router>
  );
}

export default App;
