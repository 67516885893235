

function ContactUs() {
    return (
            <div className='mx-auto text-center flex flex-col items-center rounded-2xl max-w-[600px] px-10 pb-10' id='contact'>
                <h1 className='md:text-5xl sm:text-4xl text-3xl font-bold pt-4 md:pt-10 text-indigo-300 antialiased pb-8'>Contact Us</h1>
                <div className='shadow-xl shadow-black border rounded-lg border-indigo-300 px-10 py-4 bg-[#1d1c28] w-full'>
                    <form class="w-full pt-10">
                    <div class="md:flex md:items-center mb-6">
                    <div class="md:w-1/4 ">
                        <label class="block text-white font-bold md:text-md md:text-right mb-1 md:mb-0 pr-4" for="inline-first-name">
                        First Name
                        </label>
                    </div>
                    <div class="md:w-3/4">
                        <input class="appearance-none bg-transparent border-b border-gray-500  invalid:border-red-500 w-full text-sm text-gray-200 mr-3 py-1 px-2 leading-tight focus:bg-[#0a0a16]" type="text" placeholder="" aria-label="First name"/>
                    </div>
                    </div>
                    <div class="md:flex md:items-center mb-6">
                        <div class="md:w-1/4">
                            <label class="block text-white font-bold md:text-md md:text-right mb-1 md:mb-0 pr-4" for="inline-last-name">
                            Last Name
                            </label>
                        </div>
                        <div class="md:w-3/4">
                        <input class="appearance-none bg-transparent border-b border-gray-500 w-full text-sm text-gray-200 mr-3 py-1 px-2 leading-tight focus:bg-[#0a0a16]" type="text" placeholder="" aria-label="Last name"/>
                        </div>
                    </div>
                    <div class="md:flex md:items-baseline mb-6">
                        <div class="md:w-1/4">
                            <label class="block text-white font-bold md:text-md md:text-right mb-1 md:mb-0 pr-4" for="inline-email">
                            Email
                            </label>
                        </div>
                        <div class="md:w-3/4">
                            <input class="appearance-none bg-transparent border-b border-gray-500 w-full text-sm text-gray-200 mr-3 py-1 px-2 leading-tight focus:bg-[#0a0a16]" type="email" placeholder="" aria-label="Email"/>
                            <label class="block text-gray-500 md:text-left mb-1 md:mb-0 pr-4 text-sm" for="inline-email">
                            Required
                            </label>
                        </div>
                    </div>
                    <div class="md:flex mb-6 align-text-top ">
                        <div class="md:w-1/4 ">
                            <label class="block text-white font-bold md:text-md md:text-right mb-1 md:mb-0 pr-4" for="inline-message">
                            Message
                            </label>
                        </div>
                        <div class="md:w-3/4">
                            <div class="relative w-full min-w-[200px]">
                                <textarea
                                class="peer h-full min-h-[100px] w-full text-sm resize-none rounded-[7px] border border-gray-500  bg-transparent px-3 py-2.5 font-normal text-gray-200 focus:bg-[#0a0a16]"
                                placeholder=" "></textarea>
                            </div>
                        </div>
                    </div>
                    
                </form>
                <div class="flex flex-col items-center py-2">
                        <button class="shadow hover:bg-[#0a0a16] bg-indigo-300 hover:text-white text-black focus:shadow-outline focus:outline-none font-bold py-2 px-4 rounded w-full" type="button">
                        Send Message
                        </button>
                </div>
            </div>
        </div>
    );
}



export default ContactUs;