// src/SentenceWithGradientWord.js
import React from 'react';
import TextGradientComponent from './TextGradientComponent';
// import styled from 'styled-components';

export default function SentenceWithGradientWord({ sentence, gradientWord }) {
  const words = sentence.split(' ');

  return (
    <h1 className="font-roboto font-bold text-2xl sm:text-3xl md:text-4xl lg:text-5xl xl:text-6xl text-center">
      {words.map((word, index) => (
        <React.Fragment key={index}>
          {word === gradientWord ? (
            <TextGradientComponent>{word}</TextGradientComponent>
          ) : (
            word
          )}
          {index < words.length - 1 && ' '}
        </React.Fragment>
      ))}
    </h1>
  );
}
