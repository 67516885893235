import { FaPython, FaReact, FaMicrosoft, FaAws } from "react-icons/fa";
import {SiPrisma} from "react-icons/si";
import { PiFileSql } from "react-icons/pi";


function Languages() {
    return (
        <div className='mx-10 sm:mx-72 pb:12 flex justify-between items-center'>
            <FaPython size={30} fill='darkgray'/>
            <FaReact size={30} fill='darkgray'/>
            <FaMicrosoft size={30} fill='darkgray'/>
            <FaAws size={30} fill='darkgray'/>
            <SiPrisma size={30} fill='darkgray'/>
            <PiFileSql size={30} fill='darkgray'/>
        </div>
    );
};

export default Languages;