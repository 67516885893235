import Languages from "./Languages";


function Footer() {

    const currentYear = new Date().getFullYear();

    return (
        <div className="relative mx-auto pt-10 max-w-[1600px] justify-center">
            <div>
                <hr className="h-px mb-12 bg-gray-200 border-0 dark:bg-gray-700"></hr>
            </div>
            <Languages />
            <div className="h-12">
                <h1 className="absolute left-10 bottom-2 p-2 text-gray-500 text-xs pb-2">Laminar Innovation, LLC | &copy; {currentYear}</h1>
            </div>
        </div>
    );
}


export default Footer;