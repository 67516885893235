// import SporadicLetters from './SporadicLetters/SporadicLetters';
// import TextGradientComponent from './Gradient/Gradient';
import SentenceWithGradientWord from './Gradient/SentenceGradient';
import PlaneTrails from './PlaneTrails/PlaneTrails';

function Discover() {
    return (
        <div className='text-white h-full m-2'>
            <div className='max-w-[800px] mt-[-96px] w-full h-screen mx-auto text-center flex flex-col justify-center'>
                <div className='flex'>
                    <SentenceWithGradientWord 
                        sentence='Reducing the turbulence in your day-to-day.'
                        gradientWord='turbulence'
                    />
                </div>
                <p className='lg:text-xl md:text-lg sm:text-base text-sm font-bold pt-8'>Our team is here to help take your business processes from turbulent to laminar.</p>
            </div>
            <div className='-z-10 h-screen w-full absolute left-0 top-0'>
                <PlaneTrails />
            </div>
        </div>
    );
}


export default Discover;