
import {useState, useEffect} from 'react';
import {AiOutlineClose, AiOutlineMenu} from 'react-icons/ai';
// import { ReactComponent as LI } from '../assets/li-shorthand_arial.svg';



function Navbar() {
    const [nav, setNav] = useState(false);

    const [navTitle, setNavTitle] = useState('LAMINAR INNOVATION');

    useEffect(() => {
        const handleResize = () => {
            if (window.innerWidth <= 768) {
                setNavTitle('LI');
            } else {
                setNavTitle('LAMINAR INNOVATION');
            }
        };

        window.addEventListener('resize', handleResize);
        handleResize(); // Set initial value

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const handleNav = () => {
        setNav(!nav);
    }


    return (
        <div className='pb-4'>
            <div className='flex justify-between items-center h-16 mx-auto px-4 text-white max-w-[1600px]'>
                <h1 className='w-full text-xl lg:text-2xl roboto font-bold'>{navTitle}</h1>
                <ul className='hidden md:flex text-md lg:text-xl'>
                    <a className='p-4 md:px-5 cursor-pointer hover:text-[#9C9AC5]' href="#about">About</a>
                    <a className='p-4 md:px-5 cursor-pointer hover:text-[#9C9AC5]' href="#process">Process</a>
                    <a className='p-4 md:px-5 cursor-pointer hover:text-[#9C9AC5]' href="#contact">Contact</a>
                </ul>
                <div onClick={handleNav} className={`block md:hidden ${nav ? 'fixed top-5 right-4 z-20' : ''}`}>
                    {nav ? <AiOutlineClose size={20}/> : <AiOutlineMenu size={20}/>}
                </div>
                <div className={nav ? 'fixed left-0 top-0 w-[60%] h-full border-r border-r-gray-800 bg-gradient-to-b from-[#131724] to-[#0a0a16] ease-in-out duration-500 z-20 opacity-95' : 'fixed left-[-100%]'}>
                    <h1 className='w-full text-xl font-bold m-4'>LI</h1>
                    <ul className='uppercase p-4 flex flex-col'>
                        <a className='p-4 border-b border-gray-600' href="#about">About</a>
                        <a className='p-4 border-b border-gray-600' href="#process">Process</a>
                        <a className='p-4 border-b border-gray-600' href="#contact">Contact</a>
                    </ul>
                </div>
            </div>
        </div>
    )
}

export default Navbar;