import React from 'react';
import Definition from './Definition.jsx';

const About = () => {
    return (
        <div className='text-black text-2xl sm:text-3xl md:text-4xl lg:text-5xl bg-gradient-to-b from-[#0a0a16] to-[#131724]'  id='about'>
            <div className='w-full pt-4 md:pt-10 mx-auto text-center'>
                <h1 className='text-indigo-300 md:text-5xl sm:text-4xl text-3xl font-bold antialiased'>About</h1>
            </div>
            <div className='w-full md:pt-10 mx-auto text-center justify-center max-w-[1600px]'>
                <div className='flex justify-around items-center flex-col md:flex-row pt-4'>
                    {/* <Turbulent className='bg-white rounded-xl shadow-2xl shadow-black px-2 scale-75 lg:scale-100'></Turbulent>
                    <Laminar className='bg-white rounded-xl shadow-2xl shadow-black px-2 scale-75 lg:scale-100'></Laminar> */}
                    <Definition data={{word: 'Turbulent',
                                        definition_1: 'a state of confusion and lack of order', 
                                        definition_2: 'departure in a fluid from a smooth flow (aerodynamics)'}}/>
                    <Definition data={{word: 'Laminar',
                                        definition_1: 'smooth and orderly with minimal disruption', 
                                        definition_2: 'fluid flows in regular paths and streamlines (aerodynamics)'}}/>
                    
                </div>
                <div className='md:mx-44'>
                    <p className='text-center px-4 md:px-0 pt-4 md:pt-28 lg:pt-36 2xl:pt-48 text-white text-sm md:text-lg'>Laminar Innovation is a team of developers who specialize in creating efficient and effective business processes unique to your problem statement. 
                        We are dedicated to helping you streamline your day-to-day operations, so you can focus on what you do best. Capable of full stack development, we handle the user experience and backend connections 
                        to meet your software demands.</p>
                </div>
            </div>
        </div>
    );
};

export default About;